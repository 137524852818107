import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TransitionLeft } from '../../../helpers/utils'
import { apiFetch } from '../../../service/apiFetch'
import { Zoom, Grid, CircularProgress } from '@mui/material';
import AboutOfficeSpecialist from './InformationItem/AboutOfficeSpecialist'
import Specialities from './InformationItem/Specialities'
import TargetAudiences from './InformationItem/TargetAudiences'
import OfficeSpecialistAddress from './InformationItem/OfficeSpecialistAddress'
export default function Information({
                                        setOpenSnackbar,
                                        setText,
                                        setTransition,
                                        setSeverity,
                                        darkState,
                                        zoom,
                                        phoneResponsive,
                                        setOfficeSpecialist,
                                        officeSpecialist,
                                    }) {
    const { id } = useParams()
    let navigate = useNavigate()
    const mounted = useRef(false);

    useEffect(() => {
        (async () => {
            mounted.current = true
            const officeSpecialistFetch = await apiFetch(`/office_specialists/${id}`)
            if (officeSpecialistFetch && officeSpecialistFetch.uuid && mounted.current) {
                setOfficeSpecialist(officeSpecialistFetch)
            } else {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/office_specialists')
            }
            return () => mounted.current = false
        })()
    }, [setOfficeSpecialist, setTransition, setText, setSeverity, setOpenSnackbar, id, navigate])

    return (
        <>
            {officeSpecialist ? <Zoom in={zoom}>
                    <div style={phoneResponsive ? {maxWidth: 215} : {}}>
                        <Grid container spacing={3} style={{marginTop: -38}}>
                            <Grid item xs={12} sm={6}>
                                <AboutOfficeSpecialist officeSpecialist={officeSpecialist} darkState={darkState} zoom={zoom} />
                                <OfficeSpecialistAddress officeSpecialist={officeSpecialist} darkState={darkState} zoom={zoom} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Specialities officeSpecialist={officeSpecialist} darkState={darkState} zoom={zoom} />
                                <TargetAudiences officeSpecialist={officeSpecialist} darkState={darkState} zoom={zoom} />
                            </Grid>
                        </Grid>
                    </div>
                </Zoom> :
                <div style={{textAlign: 'center'}}>
                    <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                </div>}
        </>
    )
}
