import React from 'react'
import {
    Paper,
    Zoom,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SpecialistAddress({ officeSpecialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '500ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'}
                            style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Adresse(s)</span>
                </Typography>
                <div style={{marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adresse(s) du cabinet : </span>
                    </Typography>
                    {officeSpecialist.offices.length ? officeSpecialist.offices.map((office) => {
                            return <Typography key={office.id} style={{marginLeft: 50}}>
                                • {office.address.address}
                            </Typography>
                        }) :
                        <Typography style={{marginLeft: 50}}><CloseIcon style={{color: 'red'}}/></Typography>}
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adresse URSAFF : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.urssafAddress || 'Non renseigné'}
                    </Typography>
                </div>
            </Paper>
        </Zoom>
    )
}


