export const routes = {
    login: '/login',
    home: '/',
    specialistList: '/specialists',
    showSpecialistList: '/specialists/:id',
    editSpecialistList: '/specialists/:id/edit',
    addSpecialistList: '/specialists/add',
    dashboard: '/dashboard',
    ReponseSMS: '/intervention_requests/:uuid/response',
    interventions: '/interventions',
    newIntervention: '/interventions/new',
    editIntervention: '/interventions/:id/edit',
    userAccount: '/users',
    editUserAccount: '/users/:id/edit',
    editAdminAccount: '/profil/:id',
    company: '/companies',
    editCompany: '/companies/:id/edit',
    newCompany: '/companies/new',
    certifications: '/certifications',
    editCertifications: '/certifications/:id/edit',
    newCertifications: '/certifications/new',
    specialities: '/specialities',
    editSpecialities: '/specialities/:id/edit',
    newSpecialities: '/specialities/new',
    experiences: '/experiences',
    editExperiences: '/experiences/:id/edit',
    newExperiences: '/experiences/new',
    expertises: '/expertises',
    editExpertises: '/expertises/:id/edit',
    newExpertises: '/expertises/new',
    languages: '/languages',
    editLanguages: '/languages/:id/edit',
    newLanguages: '/languages/new',
    administratifStatus: '/administratif-status',
    editAdministratifStatus: '/administratif-status/:id/edit',
    newAdministratifStatus: '/administratif-status/new',
    specialistsMap: '/map',
    manageCv: '/cvs/:id/edit',
    restore: '/restore',
    prospects: '/prospects',
    showProspectList: '/prospects/:id',
    editProspectList: '/prospects/:id/edit',
    convertProspect: '/office_specialist/:id/convert',
    officeSpecialists: '/office_specialists',
    showOfficeSpecialist: '/office_specialists/:id',
    editOfficeSpecialist: '/office_specialists/:id/edit',
}