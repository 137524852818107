import React from 'react'
import {
    Paper,
    Zoom,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SpecialistAddress({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '500ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Adresse(s)</span>
                </Typography>
                <Typography color={darkState ? 'secondary' : 'primary'}>
                    <span style={{fontWeight: 'bold'}}>Adresse(s) du cabinet : </span>
                </Typography>
                {specialist.offices.length ? specialist.offices.map((office) => {
                    return <Typography key={office.id} style={{marginLeft: 50}}>
                        • {office.address.address}
                    </Typography>
                }) : 
                <Typography style={{marginLeft: 50}}><CloseIcon style={{color: 'red'}} /></Typography>}
                <Typography color={darkState ? 'secondary' : 'primary'}>
                    <span style={{fontWeight: 'bold'}}>Adresse(s) personnelle : </span>
                </Typography>
                {specialist.address ?
                    <Typography style={{marginLeft: 50}}>
                        • {specialist.address.address} {specialist.address.zipCode} {specialist.address.city}
                    </Typography>
                : 
                <Typography style={{marginLeft: 50}}>Pas d'adresse...</Typography>}
                <Typography color={darkState ? 'secondary' : 'primary'}>
                    <span style={{fontWeight: 'bold'}}>Adresse URSSAF : </span>
                </Typography>
                {specialist.urssafAddress ?
                    <Typography style={{marginLeft: 50}}>
                        • {specialist.urssafAddress} {specialist.urssafAddressZipCode} {specialist.urssafAddressCity}
                    </Typography>
                    :
                    <Typography style={{marginLeft: 50}}>Pas d'adresse...</Typography>}
            </Paper>
        </Zoom>
    )
}


