import React from 'react'
import { Paper, Zoom, Typography } from '@mui/material';

export default function AboutOfficeSpecialist({ officeSpecialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'}
                            style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>À propos</span>
                </Typography>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Psychologue en cabinet : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.lastName && officeSpecialist.firstName
                            ? `${officeSpecialist.lastName.toUpperCase()} ${officeSpecialist.firstName.charAt(0).toUpperCase() + officeSpecialist.firstName.slice(1).toLowerCase()}`
                            : officeSpecialist.lastName
                                ? officeSpecialist.lastName.toUpperCase()
                                : officeSpecialist.firstName
                                    ? officeSpecialist.firstName.charAt(0).toUpperCase() + officeSpecialist.firstName.slice(1).toLowerCase()
                                    : 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adresse mail : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.email || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Spécialisation : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.specialistType || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Statut fiscal : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {(officeSpecialist.taxStatus) || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>RPPS : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.rpps || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Siret : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.siret || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex', marginBottom: 10}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>N° mobile pro : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {officeSpecialist.mobilePro || 'Non renseigné'}
                    </Typography>
                </div>
            </Paper>
        </Zoom>
    )
}
