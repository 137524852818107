import React, { useEffect, useState } from 'react'
import {
    Chip,
    LinearProgress,
    ButtonGroup,
    Button,
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tooltip,
    Zoom,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material/';
import { apiFetch } from '../service/apiFetch';
import { removeLastAmpersand, TransitionLeft } from '../helpers/utils';
import FilterListIcon from '@mui/icons-material/FilterList';
import AutoCompleteAddress from './AutoCompleteAddress';

function FilterList({
    hasFilter,
    setDisableFilterList,
    disableFilterList,
    setUpdateFilter,
    darkState,
    filterList,
    state,
    setState,
    setOfficeSpecialistType,
    setFirstName,
    setLastName,
    setNewCoordonnate
}) {

    const handleRemoveFilter = (e, list, item) => {
        if (disableFilterList) return
        setDisableFilterList(true)
        const func = findRightFunction(
            item,
            setOfficeSpecialistType,
            setFirstName,
            setLastName,
            setNewCoordonnate
        )
        if (list.length < 2) {
            func("")
        } else if (typeof item === 'string') {
            setState({ ...state, [item]: false });
        } else {
            const remove = list.filter((i) => i.id !== item.id)
            func(remove)
        }
        setUpdateFilter(true)
    }

    const findRightFunction = (
        key,
        setOfficeSpecialistType,
        setFirstName,
        setLastName,
    ) => {
        switch(key['@type']) {
            case "specialistType":
                return setOfficeSpecialistType
            case 'firstName':
                return setFirstName
            case 'lastName':
                return setLastName
            case 'ville':
                return setNewCoordonnate
            default:
                return false
        }
    }

    return (
        <>
            {hasFilter() && <><Typography style={{width: 225, marginTop: 15}}>Filtre(s) en cours : </Typography>
                <div style={{display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', marginTop: 15}}>
                    {filterList && !!filterList.length && filterList.map((list, i) => {
                        return <React.Fragment key={i}>
                            {list && list.length > 0 && list.map((item, index) => (
                                    item && item.name && <div key={index} style={{display: 'flex', marginRight: 10, marginBottom: 10}}>
                                        <Chip
                                            size="small"
                                            label={item.name}
                                            onDelete={(e) => handleRemoveFilter(e, list, item)}
                                            color={darkState ? 'secondary' : 'primary'}
                                        />
                                    </div>
                                ))}
                        </React.Fragment>
                    })}
                </div>
            </>}
        </>
    )
}

export default function OfficeSpecialistFilters({
    isFromMap,
    setNewCoordonnate,
    newCoordonnate,
    isFromList,
    setIsFiltered,
    setAllOfficeSpecialists,
    filterList,
    setFilterList,
    setNoOfficeSpecialist,
    state,
    setState,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    officeSpecialistType,
    setOfficeSpecialistType,
    phoneResponsive,
    fetchOfficeSpecialists,
    setRefresh,
    setOfficeSpecialists,
    darkState,
    expanded,
    setExpanded,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity
}) {
    const [disabledFilter, setDisabledFilter] = useState(false)
    const [disableFilterList, setDisableFilterList] = useState(false)
    const [updateFilter, setUpdateFilter] = useState(false)

    useEffect(() => {
        (async () => {
            if (filterList) {
                await handleFilter()
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        setOpenSnackbar,
        setText,
        setTransition,
        setSeverity,
    ])

    const handleFilter = async () => {
        setDisabledFilter(true)
        setUpdateFilter(false)
        const officeSpecialistTypeFilter = officeSpecialistType ? `specialistType=${officeSpecialistType}&` : ''
        const foundFirstName = firstName ? `firstName=${firstName}&` : ''
        const foundLastName = lastName ? `lastName=${lastName}&` : ''
        const foundCity = newCoordonnate ? `distance_from=${newCoordonnate.lat},${newCoordonnate.lng}&` : ''
        const url = removeLastAmpersand(`/office_specialists?${officeSpecialistTypeFilter}${foundFirstName}${foundLastName}${foundCity}`)

        setFilterList([
            [{'@type': 'firstName', name: firstName}],
            [{'@type': 'lastName', name: lastName}],
            [{'@type': 'specialistType', name: officeSpecialistType}],
            newCoordonnate ? [{'@type': 'ville', name: 'Ville'}] : false
        ])
        setRefresh && setRefresh(true)
        const filtered = fetchOfficeSpecialists ? await fetchOfficeSpecialists(url) : await apiFetch(url)
        if (filtered) {
            if (filtered.length === 0) setNoOfficeSpecialist && setNoOfficeSpecialist(true)
            setIsFiltered && setIsFiltered(true)
            setOfficeSpecialists && setOfficeSpecialists(filtered)
            setAllOfficeSpecialists && setAllOfficeSpecialists(filtered)
        } else {
            setText((officeSpecialistType && officeSpecialistType['hydra:description']) || 'Erreur lors de la filtration des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setRefresh && setRefresh(false)
        setExpanded(false)
        setDisabledFilter(false)
        setDisableFilterList(false)
    }

    const handleDeleteFilter = async () => {
        setDisabledFilter(true)
        const deleteFiltered = fetchOfficeSpecialists ?
            await fetchOfficeSpecialists(`/office_specialists`) :
            await apiFetch(`/office_specialists`)

        if (deleteFiltered && (deleteFiltered.length || deleteFiltered['hydra:member'])) {
            setOfficeSpecialistType('')
            setFilterList && setFilterList(false)
            setOfficeSpecialists && setOfficeSpecialists(deleteFiltered)
            setAllOfficeSpecialists && setAllOfficeSpecialists(deleteFiltered)
        } else {
            setText((officeSpecialistType && officeSpecialistType['hydra:description']) || 'Erreur lors de la récupération des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setRefresh && setRefresh(false)
        setExpanded(!expanded)
        setDisabledFilter(false)
    }

    const hasFilter = () => {
        return (firstName ||
            lastName ||
            officeSpecialistType ||
            newCoordonnate)
    }

    return (
        <Zoom in={zoom} style={{ transitionDelay: '1000ms' }}>
            <Accordion
                style={phoneResponsive ?
                    {
                        marginBottom: 30,
                        boxShadow: '0 4px 4px 0 rgb(34 41 47 / 10%)',
                        borderRadius: 4,
                        maxWidth: 257
                    } :
                    {
                        marginBottom: 30,
                        boxShadow: '0 4px 4px 0 rgb(34 41 47 / 10%)',
                        borderRadius: 4
                    }
                }
                expanded={expanded}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{cursor: 'default'}}
                >
                    {updateFilter && handleFilter()}
                    <Typography
                        style={expanded || !hasFilter() ? {flex: '1 1 100%',} : {}}
                        color={darkState ? 'secondary' : 'primary'}
                        sx={{
                            alignItems: 'center',
                            fontSize: 24,
                            mt: 1,
                            mr: {sm: 10, xs: 2},
                        }}
                    >
                        Filtres
                    </Typography>
                    {!expanded && <FilterList
                        setNewCoordonnate={setNewCoordonnate}
                        hasFilter={hasFilter}
                        disableFilterList={disableFilterList}
                        setUpdateFilter={setUpdateFilter}
                        setDisableFilterList={setDisableFilterList}
                        handleFilter={handleFilter}
                        setFilterList={setFilterList}
                        state={state}
                        setState={setState}
                        officeSpecialistType={officeSpecialistType}
                        setOfficeSpecialistType={setOfficeSpecialistType}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        darkState={darkState}
                        filterList={filterList}
                    />}
                    <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={() => setExpanded(!expanded)}>
                        <Tooltip TransitionComponent={Zoom} title={'Liste des filtres'}>
                            <FilterListIcon />
                        </Tooltip>
                    </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Typography color={darkState ? 'secondary' : 'primary'}>Données communes</Typography>
                            <div style={{marginBottom: 50}}>
                                {isFromMap && <AutoCompleteAddress
                                    setRefresh={setRefresh}
                                    setExpanded={setExpanded}
                                    darkState={darkState}
                                    id="city"
                                    name="city"
                                    label="Ville"
                                    defaultValue={''}
                                    setNewCoordonnate={setNewCoordonnate}
                                /> }
                            </div>
                            {isFromList && <div style={{marginTop: -20, marginBottom: 20}}>
                                <AutoCompleteAddress
                                    setRefresh={setRefresh}
                                    darkState={darkState}
                                    id="city"
                                    name="city"
                                    label="Ville"
                                    defaultValue={''}
                                    setNewCoordonnate={setNewCoordonnate}
                                />
                            </div>}
                            <FormControl color={darkState ? 'secondary' : 'primary'} fullWidth style={{ marginBottom: 40 }}>
                                <InputLabel id="specialist-type-label">Type de spécialistes</InputLabel>
                                <Select
                                    labelId="specialist-type-label"
                                    id="specialist-type"
                                    value={officeSpecialistType || ''}
                                    onChange={(e) => setOfficeSpecialistType(e.target.value)}
                                    label="Type de spécialistes"
                                >
                                    <MenuItem value="">Sélectionner</MenuItem>
                                    <MenuItem value="Clinique">Clinique</MenuItem>
                                    <MenuItem value="Travail">Travail</MenuItem>
                                </Select>
                            </FormControl>
                            <ButtonGroup
                                variant="contained"
                                color={darkState ? 'secondary' : 'primary'}
                                aria-label="text primary button group"
                                style={{margin: 'auto'}}
                            >
                                <Button disabled={disabledFilter} onClick={handleFilter}> Filtrer </Button>
                                <Button disabled={disabledFilter} onClick={handleDeleteFilter}> Supprimer les filtres </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                {disabledFilter && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Accordion>
        </Zoom>
    )
}
