import { routes } from "../service/Routes";
import Slide from '@mui/material/Slide';

export function isKnownRoutes(route, get = false) {
    const r = route.lastIndexOf('/')
    let formattedRoute = route
    if (r > 0) {
        formattedRoute = route.substring(0,r) + '/:id'
    }
    if (get) {
        return formattedRoute
    }
    return Object.keys(routes).find((key) => !!(routes[key] === formattedRoute)); 
}

export function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export const progressBarWidth = (e, setStyle) => {
    let percentage = 0
    let background = null

    if (e.target.value.length < 4) {
        percentage = 0;
        background = "#dd4b39";
    } else if (e.target.value.length < 6) {
        percentage = 30;
        background = "#ff9800";
    } else if (e.target.value.length < 8) {
        percentage = 50;
        background = "#ff9800";
    } else {
        percentage = 70;
        background = "#4caf50";
    }

    if ((e.target.value.match(/[a-z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/[A-Z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/0|1|2|3|4|5|6|7|8|9/) != null)) {
        percentage += 10;
    }

    setStyle({width: percentage, color: background})
}

export function removeLastComma(strng){  
    const n=strng.lastIndexOf(",");
    if (n > 0) {
        const a=strng.substring(0,n)
        return a;
    }
    return strng
}

export function formatAddress(newAddress, newCoordonate) {
    if (!newAddress || !newCoordonate|| !Array.isArray(newAddress)) return null

    return {
        address:newAddress[0].formatted_address,
        latitude: newCoordonate.lat,
        longitude: newCoordonate.lng,
    }
}

export function isEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    const element = {}
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        if (a[propName] !== b[propName]) {
            element[propName] = b[propName]
        }
    }

    return element.length === 0 || element;
}

export function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function removeLastAmpersand(strng){  
    const n=strng.charAt(strng.length-1);
    if (n === '&') {
        const a=strng.substring(0, strng.length - 1)
        return a;
    }
    return strng
}

export function translateStatus(status) {
    switch(status) {
        case 'Pending':
            return 'En attente'
        case 'Canceled':
            return 'Annulée'
        default:
            return 'Validée'
    }
}

export function getRightColor(status) {
    switch(status) {
        case "valide":
            return 'rgba(0, 160, 38, 0.7)'
        case "renouvellement dépassé":
            return 'rgba(0, 0, 0, 0.7)'
        case "renouvellement urgent":
            return "rgba(255, 0, 0, 0.7)"
        default:
            return 'rgba(234, 161, 16, 0.7)'
    }
}

export function formatMultipleCompanies(c) {
    let tab = []
    c.map((value) => {
        if (value['@id']) {
            return tab.push(value['@id'])
        }
        return null
    })
    return tab
}

export function formatMultipleOptions(identifier, options) {
    let s =''
    options.map((option) => {
        if (option) {
            return s += `${identifier}[]=${option.name}&`
        }
        return null
    })
    return s
}

export function handleFilter(speciality, firstName, lastName, language, administrativeType, specialistType, certification, state) {
    const chosenSpecialities = speciality ? formatMultipleOptions('specialities.name', speciality) : ''
    const foundFirstName = firstName ? `firstName=${firstName}&` : ''
    const foundLastName = lastName ? `lastName=${lastName}&` : ''
    const languagesFilter = language ? formatMultipleOptions('languages.name', language) : ''
    const administrativeTypeFilter = administrativeType ? formatMultipleOptions('administrativeTypes.name', administrativeType) : ''
    const specialistTypeFilter = specialistType ? formatMultipleOptions('specialistType.name',specialistType) : ''
    const certificationFilter = certification ? formatMultipleOptions('certifications.name',certification) : ''
    const officeFilter = state.office ? `offices=${state.office}&` : ''
    const homeInterventionFilter = state.homeIntervention ? `activity.homeInterventions=${state.homeIntervention}&` : ''
    const appFilter = state.app ? `activity.app=${state.app}&` : ''
    const auditFilter = state.audit ? `activity.audit=${state.audit}&` : ''
    const coachingFilter = state.coaching ? `activity.coaching=${state.coaching}&` : ''
    const conflictResolutionSupportFilter = state.conflictResolutionSupport ? `activity.conflictResolutionSupport=${state.conflictResolutionSupport}&` : ''
    const crisisUnitFilter = state.crisisUnit ? `activity.crisisUnit=${state.crisisUnit}&` : ''
    const harassmentInvestigationFilter = state.harassmentInvestigation ? `activity.harassmentInvestigation=${state.harassmentInvestigation}&` : ''
    const certifiedCoachingFilter = state.certifiedCoaching ? `activity.certifiedCoaching=${state.certifiedCoaching}&` : ''
    const platformFilter = state.platform ? `activity.platform=${state.platform}&` : ''
    const projectManagerFilter = state.projectManager ? `activity.projectManager=${state.projectManager}&` : ''
    const pseFilter = state.pse ? `activity.pse=${state.pse}&` : ''
    const gpVisioFilter = state.gpVisio ? `activity.visio=${state.gpVisio}&` : ''
    const gpAudioFilter = state.gpAudio ? `activity.call=${state.gpAudio}&` : ''
    return {
        chosenSpecialities,
        foundFirstName,
        foundLastName,
        languagesFilter,
        administrativeTypeFilter,
        specialistTypeFilter,
        certificationFilter,
        officeFilter,
        homeInterventionFilter,
        appFilter,
        auditFilter,
        coachingFilter,
        conflictResolutionSupportFilter,
        crisisUnitFilter,
        harassmentInvestigationFilter,
        platformFilter,
        projectManagerFilter,
        pseFilter,
        gpVisioFilter,
        gpAudioFilter,
        certifiedCoachingFilter,
    }
}

export function handleOfficeSpecialistFilter(officeSpecialistType, firstName, lastName) {
    const officeSpecialistTypeFilter = officeSpecialistType ? `specialistType=${officeSpecialistType}&` : ''
    const foundFirstName = firstName ? `firstName=${firstName}&` : ''
    const foundLastName = lastName ? `lastName=${lastName}&` : ''

    return {
        officeSpecialistTypeFilter,
        foundFirstName,
        foundLastName,
    }
}

export function handleMultipleTextFieldValue(value, experiences) {
    const tab = []
    if (experiences) {
        value.map((item) => {
            if (item.isNew) {
                tab.push({
                    experience: {
                        name: item.name
                    },
                    description: item.description            
                })
            } else {
                tab.push({
                    experience: item.isAdd ? item.experience['@id'] : item.experience,
                    description: item.description
                })
            }
            return null
        })
    } else {
        value.length && value.map((i) => tab.push(i['@id']))
    }
    return tab
}

export function convertActivitiesToObject(tab) {
    return {
        homeIntervention: !!tab.find((item) => item.id === 'homeIntervention'),
        platform: !!tab.find((item) => item.id === 'platform'),
        crisisUnit: !!tab.find((item) => item.id === 'crisisUnit'),
        conflictResolutionSupport: !!tab.find((item) => item.id === 'conflictResolutionSupport'),
        pse: !!tab.find((item) => item.id === 'pse'),
        app: !!tab.find((item) => item.id === 'app'),
        coaching: !!tab.find((item) => item.id === 'coaching'),
        certifiedCoaching: !!tab.find((item) => item.id === 'certifiedCoaching'),
        projectManager: !!tab.find((item) => item.id === 'projectManager'),
        harassmentInvestigation: !!tab.find((item) => item.id === 'harassmentInvestigation'),
        call: !!tab.find((item) => item.id === 'call'),
        visio: !!tab.find((item) => item.id === 'visio'),
        rpsCoach: !!tab.find((item) => item.id === 'rpsCoach'),
    }
}

export function convertActivitiesToArray(object) {
    const tab = []
    Object.keys(object).map((item) => {
        if (typeof(object[item]) === "boolean" && object[item]) {
            return tab.push({id: item, name: translateActivityName(item), '@type': 'Activity'})
        }
        return null
    })
    return tab
}

function translateActivityName(item) {
    switch(item) {
        case 'homeIntervention':
            return 'Intervention à domicile'
        case 'platform':
            return 'Ligne d\'écoute'
        case 'crisisUnit':
            return 'Cellules de crise'
        case 'conflictResolutionSupport':
            return 'Appui à la résolution de conflits, médiation'
        case 'pse':
            return 'Permanence sur site'
        case 'app':
            return 'Analyse de la Pratique Professionnelle'
        case 'coaching':
            return 'Coaching individuel et collectif'
        case 'certifiedCoaching':
            return 'Coaching certifié'
        case 'projectManager':
            return 'Chef de projet/audit'
        case 'rpsCoach':
            return 'Formateur dans la prévention des RPS auprès de salariés et de managers'
        case 'visio':
            return 'Visio'
        case 'call':
            return 'Appel'
        default:
            return 'Enquête harcèlement'
    }
}

export function formatExperiences(data) {
    const tab = []
    data && data.map((item) => tab.push({name: item.experience.name, description: item.description, experience: item.experience && item.experience['@id']}))
    return tab
}