import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_OFFICE_SPECIALIST = 'FETCHING_OFFICE_SPECIALIST'
const SET_OFFICE_SPECIALIST = 'SET_OFFICE_SPECIALIST'
const CONVERT_OFFICE_SPECIALIST = 'CONVERT_OFFICE_SPECIALIST'
const UPDATE_OFFICE_SPECIALIST = 'UPDATE_OFFICE_SPECIALIST'
const DELETE_OFFICE_SPECIALIST = 'DELETE_OFFICE_SPECIALIST'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_OFFICE_SPECIALIST:
            return { ...state, loading: true }
        case SET_OFFICE_SPECIALIST:
            return {...state, officeSpecialists: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], officeSpecialistView: action.payload['hydra:view']}
        case CONVERT_OFFICE_SPECIALIST:
            return {...state, officeSpecialists: action.payload }
        case UPDATE_OFFICE_SPECIALIST:
            return {...state, officeSpecialists: action.payload }
        case DELETE_OFFICE_SPECIALIST:
            return {...state, officeSpecialists: state.officeSpecialists.filter((i) => {
                    return i.uuid !== action.payload
                })}
        default:
            return state
    }
}

export function useOfficeSpecialists() {
    const [state, dispatch] = useReducer(reducer, {
        officeSpecialists: null,
        loading: false,
        totalItems: 0,
        officeSpecialistView: null,
    })

    return {
        officeSpecialists: state.officeSpecialists,
        totalItems: state.totalItems,
        officeSpecialistView: state.officeSpecialistView,
        fetchOfficeSpecialists: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.officeSpecialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_OFFICE_SPECIALIST' })
            const officeSpecialists = await apiFetch(route || '/office_specialists', {method: 'GET'})

            if (isSubscribed().current && officeSpecialists && officeSpecialists['hydra:member']) {
                dispatch({type: 'SET_OFFICE_SPECIALIST', payload: officeSpecialists})
            }
            return officeSpecialists && officeSpecialists['hydra:member']
        },
        updateOfficeSpecialist: async function(officeSpecialistId, data, forceResponse) {
            const updatedOfficeSpecialist = await apiFetch('/office_specialists/' + officeSpecialistId, {
                method: 'PATCH',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedOfficeSpecialist.violations && !updatedOfficeSpecialist['hydra:description']) {
                dispatch({type: 'UPDATE_OFFICE_SPECIALIST', payload: updatedOfficeSpecialist })
            }
            return updatedOfficeSpecialist
        },
        convertOfficeSpecialist: async function(officeSpecialistId, data, forceResponse) {
            const convertedOfficeSpecialist = await apiFetch('/office_specialist/' + officeSpecialistId + '/convert', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!convertedOfficeSpecialist.violations && !convertedOfficeSpecialist['hydra:description']) {
                dispatch({type: 'CONVERT_OFFICE_SPECIALIST', payload: convertedOfficeSpecialist })
            }
            return convertedOfficeSpecialist
        },
        deleteOfficeSpecialist: async function (officeSpecialistId) {
            const deletedOfficeSpecialist = await apiFetch('/office_specialists/' + officeSpecialistId, {
                method: 'DELETE'
            }, true)
            if (deletedOfficeSpecialist && deletedOfficeSpecialist.ok) {
                dispatch({type: 'DELETE_OFFICE_SPECIALIST', payload: deletedOfficeSpecialist})
            }
            return deletedOfficeSpecialist
        },
    }
}