import React, { useEffect, useRef, useState } from 'react'
import { Paper, Typography, Button, LinearProgress, Zoom, Box } from '@mui/material';
import { apiFetch } from '../../service/apiFetch';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TransitionLeft } from '../../helpers/utils';
import { handleFormError } from '../../service/security/formError';
import Information from '../ManageProspectItem/Information';
import {useProspects} from "../../hooks/prospects";
import {useOfficeSpecialists} from "../../hooks/officeSpecialists";

export default function ManageProspect({setText, setTransition, setSeverity, setOpenSnackbar, darkState, zoom}) {
    const { id } = useParams()
    const [prospectEdit, setProspectEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [specialistType, setSpecialistType] = useState('')
    const [specialities, setSpecialities] = useState('')
    const [targetAudiences, setTargetAudiences] = useState('')
    const [taxStatus, setTaxStatus] = useState('')
    const [siret, setSiret] = useState('')
    const [rpps, setRpps] = useState('')
    const [officeAddresses, setOfficeAddresses] = useState('')
    const [urssafAddress, setUrssafAddress] = useState('')
    const [mobilePro, setMobilePro] = useState('')
    const [email, setEmail] = useState('')
    const [multipleAddress, setMultipleAddress] = useState([])
    const [error, setError] = useState({})
    let navigate = useNavigate()
    const mounted = useRef(false);
    const location = useLocation();
    const action = location.state?.action;

    const { updateProspect } = useProspects()
    const { convertOfficeSpecialist } = useOfficeSpecialists()


    useEffect(() => {
        setLoading(true);
        mounted.current = true;
        (async () => {
            mounted.current = true
            if (id) {
                const prospectFetch = await apiFetch(`/prospects/${id}`)
                if (prospectFetch) {
                    setFirstName(prospectFetch.firstName)
                    setLastName(prospectFetch.lastName)
                    setSpecialistType(prospectFetch.specialistType)
                    setSpecialities(prospectFetch.specialities)
                    setTargetAudiences(prospectFetch.targetAudiences)
                    setTaxStatus(prospectFetch.taxStatus)
                    setSiret(prospectFetch.siret)
                    setRpps(prospectFetch.rpps)
                    setOfficeAddresses(prospectFetch.officeAddresses)
                    setUrssafAddress(prospectFetch.urssafAddress)
                    setMobilePro(prospectFetch.mobilePro)
                    setEmail(prospectFetch.email)
                    setProspectEdit(prospectFetch)
                } else {
                    setText((prospectFetch && prospectFetch['hydra:description']) || 'Erreur lors de la récupération des données prospects')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
                setLoading(false);
                return () => mounted.current = false
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, setOpenSnackbar, setTransition, setSeverity, setText])

    const handleBackProspects = () => {
        navigate('/prospects')
    }

    const handleChangeSpecialistType = (e) => {
        setSpecialistType(e.target.value)
    }

    const handleChangeTaxStatus = (e) => {
        setTaxStatus(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        if (action === "convert") {
            const convertedProspect = await convertOfficeSpecialist(id, {
                firstName: firstName,
                lastName: lastName,
                specialistType: specialistType,
                specialities: specialities,
                targetAudiences: targetAudiences,
                taxStatus: taxStatus,
                siret: siret,
                rpps: rpps,
                urssafAddress: urssafAddress,
                mobilePro: mobilePro,
                email: email,
                offices: multipleAddress,
            });

            if (convertedProspect && convertedProspect.uuid) {
                setText('Le prospect a été converti');
                setTransition(() => TransitionLeft);
                setSeverity('success');
                setOpenSnackbar(true);
                setTimeout(() => {
                    navigate(`/prospects`)
                }, 1000);
            } else {
                setText((convertedProspect && convertedProspect['hydra:description']) || 'Erreur lors de la conversion');
            }
        } else {
            let data = {
                firstName: firstName,
                lastName: lastName,
                specialistType: specialistType,
                specialities: specialities,
                targetAudiences: targetAudiences,
                taxStatus: taxStatus,
                siret: siret,
                rpps: rpps,
                officeAddresses: officeAddresses,
                urssafAddress: urssafAddress,
                mobilePro: mobilePro,
                email: email,
            }

            const manageProspect = await updateProspect(id, data)
            if (manageProspect && manageProspect.uuid) {
                setText('Le prospect a été modifié')
                setTransition(() => TransitionLeft)
                setSeverity('success')
                setOpenSnackbar(true)
                setTimeout(() => {
                    navigate(`/prospects/${manageProspect.uuid}`)
                }, 1000)
            } else if (manageProspect.violations) {
                const errorField = handleFormError(manageProspect,
                    [
                        'firstName',
                        'lastName',
                        'specialistType',
                        'specialities',
                        'targetAudiences',
                        'taxStatus',
                        'siret',
                        'rpps',
                        'officeAddresses',
                        'urssafAddress',
                        'mobilePro',
                        'email',
                    ]
                )
                setError(errorField)
                setText(manageProspect['hydra:description'])
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            } else {
                setText(
                    (manageProspect && manageProspect['hydra:description']) ||
                    'Erreur lors de la modification'
                )
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            setLoading(false)
        }
    }

    return (
        <Zoom in={zoom}>
            <div>
                <LinearProgress color={darkState ? 'secondary' : 'primary'} sx={{ display: loading ? 'block' : 'none' }} />
                <Paper sx={{ p: 2 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h5" color={darkState ? 'secondary' : "primary"} style={{ flex: '1 1 100%' }}>
                        {action === "convert" ? (
                            `Convertir le profil de ${firstName} ${lastName.toUpperCase()} en psychologue en cabinet`
                        ) : (
                            `Modifier le profil de ${firstName} ${lastName.toUpperCase()}`
                        )}
                        </Typography>
                        <Button onClick={handleBackProspects} color={darkState ? 'secondary' : "primary"} variant='outlined'>
                            Retour
                        </Button>
                    </div>
                </Paper>
                <Box sx={{ mt: 2 }}>
                    <form noValidate onSubmit={handleSubmit} autoComplete="off">
                        <Information
                            error={error}
                            handleChangeSpecialistType={handleChangeSpecialistType}
                            handleChangeTaxStatus={handleChangeTaxStatus}
                            lastName={lastName}
                            setLastName={setLastName}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            specialistType={specialistType}
                            setSpecialistType={setSpecialistType}
                            specialities={specialities}
                            setSpecialities={setSpecialities}
                            targetAudiences={targetAudiences}
                            setTargetAudiences={setTargetAudiences}
                            taxStatus={taxStatus}
                            setTaxStatus={setTaxStatus}
                            siret={siret}
                            setSiret={setSiret}
                            rpps={rpps}
                            setRpps={setRpps}
                            officeAddresses={officeAddresses}
                            setOfficeAddresses={setOfficeAddresses}
                            urssafAddress={urssafAddress}
                            setUrssafAddress={setUrssafAddress}
                            mobilePro={mobilePro}
                            setMobilePro={setMobilePro}
                            email={email}
                            setEmail={setEmail}
                            multipleAddress={multipleAddress}
                            setMultipleAddress={setMultipleAddress}
                            darkState={darkState}
                            id={id}
                            prospectEdit={prospectEdit}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Button
                                type="submit"
                                disabled={loading}
                                color={darkState ? 'secondary' : "primary"}
                                variant="contained"
                            >
                                {action === "convert" ? "Convertir" : "Enregistrer" }
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={handleBackProspects}
                                color={darkState ? 'secondary' : "primary"}
                                sx={{ mr: 1 }}
                            >
                                Retour
                            </Button>
                        </Box>
                    </form>
                </Box>
            </div>
        </Zoom>
    )
}
