import React, { useEffect, useRef, useState } from 'react'
import { Paper, Typography, Button, LinearProgress, Zoom, Box } from '@mui/material';
import { apiFetch } from '../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft } from '../../helpers/utils';
import { handleFormError } from '../../service/security/formError';
import Information from '../ManageOfficeSpecialistItem/Information';
import {useOfficeSpecialists} from "../../hooks/officeSpecialists";

export default function ManageOfficeSpecialist({setText, setTransition, setSeverity, setOpenSnackbar, darkState, zoom}) {
    const { id } = useParams()
    const [officeSpecialistEdit, setOfficeSpecialistEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [specialistType, setSpecialistType] = useState('')
    const [specialities, setSpecialities] = useState('')
    const [targetAudiences, setTargetAudiences] = useState('')
    const [taxStatus, setTaxStatus] = useState('')
    const [siret, setSiret] = useState('')
    const [rpps, setRpps] = useState('')
    const [urssafAddress, setUrssafAddress] = useState('')
    const [mobilePro, setMobilePro] = useState('')
    const [email, setEmail] = useState('')
    const [multipleAddress, setMultipleAddress] = useState([])
    const [error, setError] = useState({})
    let navigate = useNavigate()
    const mounted = useRef(false);

    const { updateOfficeSpecialist } = useOfficeSpecialists()


    useEffect(() => {
        setLoading(true);
        mounted.current = true;
        (async () => {
            mounted.current = true
            if (id) {
                const officeSpecialistFetch = await apiFetch(`/office_specialists/${id}`)
                if (officeSpecialistFetch) {
                    setFirstName(officeSpecialistFetch.firstName)
                    setLastName(officeSpecialistFetch.lastName)
                    setSpecialistType(officeSpecialistFetch.specialistType)
                    setSpecialities(officeSpecialistFetch.specialities)
                    setTargetAudiences(officeSpecialistFetch.targetAudiences)
                    setTaxStatus(officeSpecialistFetch.taxStatus)
                    setSiret(officeSpecialistFetch.siret)
                    setRpps(officeSpecialistFetch.rpps)
                    setMultipleAddress(officeSpecialistFetch.offices)
                    setUrssafAddress(officeSpecialistFetch.urssafAddress)
                    setMobilePro(officeSpecialistFetch.mobilePro)
                    setEmail(officeSpecialistFetch.email)
                    setOfficeSpecialistEdit(officeSpecialistFetch)
                } else {
                    setText((officeSpecialistFetch && officeSpecialistFetch['hydra:description']) || 'Erreur lors de la récupération des données du psychologue en cabinet')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
                setLoading(false);
                return () => mounted.current = false
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, setOpenSnackbar, setTransition, setSeverity, setText])

    const handleBackOfficeSpecialists = () => {
        navigate(`/office_specialists/${id}`)
    }

    const handleChangeSpecialistType = (e) => {
        setSpecialistType(e.target.value)
    }

    const handleChangeTaxStatus = (e) => {
        setTaxStatus(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        let data = {
            firstName: firstName,
            lastName: lastName,
            specialistType: specialistType,
            specialities: specialities,
            targetAudiences: targetAudiences,
            taxStatus: taxStatus,
            siret: siret,
            rpps: rpps,
            offices: multipleAddress,
            urssafAddress: urssafAddress,
            mobilePro: mobilePro,
            email: email,
        }

        const manageOfficeSpecialist = await updateOfficeSpecialist(id, data)
        if (manageOfficeSpecialist && manageOfficeSpecialist.uuid) {
            setText('Le psychologue en cabinet a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate(`/office_specialists/${manageOfficeSpecialist.uuid}`)
            }, 1000)
        } else if (manageOfficeSpecialist.violations) {
            const errorField = handleFormError(manageOfficeSpecialist,
                [
                    'firstName',
                    'lastName',
                    'specialistType',
                    'specialities',
                    'targetAudiences',
                    'taxStatus',
                    'siret',
                    'rpps',
                    'offices',
                    'urssafAddress',
                    'mobilePro',
                    'email',
                ]
            )
            setError(errorField)
            setText(manageOfficeSpecialist['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText(
                (manageOfficeSpecialist && manageOfficeSpecialist['hydra:description']) ||
                'Erreur lors de la modification'
            )
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    return (
        <Zoom in={zoom}>
            <div>
                <LinearProgress color={darkState ? 'secondary' : 'primary'} sx={{ display: loading ? 'block' : 'none' }} />
                <Paper sx={{ p: 2 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h5" color={darkState ? 'secondary' : "primary"} style={{ flex: '1 1 100%' }}>
                            Modifier le profil de {firstName} {lastName.toUpperCase()}
                        </Typography>
                        <Button onClick={handleBackOfficeSpecialists} color={darkState ? 'secondary' : "primary"} variant='outlined'>
                            Retour
                        </Button>
                    </div>
                </Paper>
                <Box sx={{ mt: 2 }}>
                    <form noValidate onSubmit={handleSubmit} autoComplete="off">
                        <Information
                            error={error}
                            handleChangeSpecialistType={handleChangeSpecialistType}
                            handleChangeTaxStatus={handleChangeTaxStatus}
                            lastName={lastName}
                            setLastName={setLastName}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            specialistType={specialistType}
                            setSpecialistType={setSpecialistType}
                            specialities={specialities}
                            setSpecialities={setSpecialities}
                            targetAudiences={targetAudiences}
                            setTargetAudiences={setTargetAudiences}
                            taxStatus={taxStatus}
                            setTaxStatus={setTaxStatus}
                            siret={siret}
                            setSiret={setSiret}
                            rpps={rpps}
                            setRpps={setRpps}
                            urssafAddress={urssafAddress}
                            setUrssafAddress={setUrssafAddress}
                            mobilePro={mobilePro}
                            setMobilePro={setMobilePro}
                            email={email}
                            setEmail={setEmail}
                            multipleAddress={multipleAddress}
                            setMultipleAddress={setMultipleAddress}
                            darkState={darkState}
                            id={id}
                            officeSpecialistEdit={officeSpecialistEdit}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Button
                                type="submit"
                                disabled={loading}
                                color={darkState ? 'secondary' : "primary"}
                                variant="contained"
                            >
                                Enregistrer
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={handleBackOfficeSpecialists}
                                color={darkState ? 'secondary' : "primary"}
                                sx={{ mr: 1 }}
                            >
                                Retour
                            </Button>
                        </Box>
                    </form>
                </Box>
            </div>
        </Zoom>
    )
}
