import React, { useEffect, useState } from 'react'
import SpecialistFilters from '../components/SpecialistFilters'
import {handleFilter, handleOfficeSpecialistFilter, removeLastAmpersand, TransitionLeft} from '../helpers/utils';
import { apiFetch } from '../service/apiFetch';
import MapContainer from './SpecialistsMap'
import { CircularProgress, Paper, Button } from '@mui/material';
import OfficeSpecialistFilters from "../components/OfficeSpecialistFilters";
import {useSpecialists} from "../hooks/specialist";
import {useOfficeSpecialists} from "../hooks/officeSpecialists";
import HomeIcon from '@mui/icons-material/Home';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

export default function SpecialistsMapFilter({
    newCoordonnate,
    setNewCoordonnate,
    filterList,
    setFilterList,
    language,
    setLanguage,
    languages,
    setLanguages,
    activity,
    setActivity,
    activities,
    setActivities,
    speciality,
    setSpeciality,
    specialities,
    setSpecialities,
    administrativeType,
    setAdministrativeType,
    administrativeTypes,
    setAdministrativeTypes,
    certification,
    setCertification,
    certifications,
    setCertifications,
    specialistType,
    setSpecialistType,
    specialistTypes,
    setSpecialistTypes,
    officeSpecialistType,
    setOfficeSpecialistType,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setState,
    state,
    darkState,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    phoneResponsive
}) {
    const [expanded, setExpanded] = useState(false)
    const [specialists, setSpecialists] = useState(false)
    const [allSpecialists, setAllSpecialists] = useState([])
    const [allOfficeSpecialists, setAllOfficeSpecialists] = useState([])
    const [officeSpecialists, setOfficeSpecialists] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [active, setActive] = useState({
        specialist: true,
        officeSpecialist: false,
    })

    const {
        fetchSpecialists,
    } = useSpecialists()

    const {
        fetchOfficeSpecialists,
    } = useOfficeSpecialists()

    useEffect(() => {
        (async () => {
            setNewCoordonnate(null)
            if (active.specialist) {
                setAdministrativeType([administrativeTypes.find((item) => item.name === 'Pros-Consulte')])

                setFilterList([speciality,
                    [{'@type': 'firstName', name: firstName}],
                    [{'@type': 'lastName', name: lastName}],
                    language,
                    [administrativeTypes.find((item) => item.name === 'Pros-Consulte')],
                    specialistType,
                    certification,
                    state,
                    activity
                ])

                const specialistsFetch = await apiFetch('/specialists?pagination=false&administrativeTypes.name[]=Pros-Consulte&')
                if (specialistsFetch && specialistsFetch['hydra:member']) {
                    setSpecialists(specialistsFetch)
                } else {
                    setText((specialistsFetch && specialistsFetch['hydra:description']) || 'Erreur de la récupération des spécialistes !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            } else {
                const officeSpecialistsFetch = await apiFetch('/office_specialists?pagination=false')
                if (officeSpecialistsFetch && officeSpecialistsFetch['hydra:member']) {
                    setOfficeSpecialists(officeSpecialistsFetch)
                } else {
                    setText((officeSpecialistsFetch && officeSpecialistsFetch['hydra:description']) || 'Erreur de la récupération des psychologues en cabinet !')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, setAdministrativeType])

    const handleChangeTab = async (tab) => {
        setAllSpecialists([])
        setAllOfficeSpecialists([])
        const getFilter = handleFilter(speciality, firstName, lastName, language, administrativeType, specialistType, certification, state)
        const getOfficeSpecialistFilter = handleOfficeSpecialistFilter(officeSpecialistType, firstName, lastName)

        switch(tab) {
            case 'specialist':
                setActive({specialist: true, officeSpecialist: false})
                const specialistFetch =
                    await fetchSpecialists(
                        removeLastAmpersand(`/specialists?${`administrativeTypes.name[]=Pros-Consulte&`}${getFilter.officeFilter}${getFilter.homeInterventionFilter}${getFilter.appFilter}${getFilter.auditFilter}${getFilter.coachingFilter}${getFilter.conflictResolutionSupportFilter}${getFilter.crisisUnitFilter}${getFilter.harassmentInvestigationFilter}${getFilter.certifiedCoachingFilter}${getFilter.platformFilter}${getFilter.projectManagerFilter}${getFilter.pseFilter}${getFilter.gpAudioFilter}${getFilter.gpVisioFilter}${getFilter.foundFirstName}${getFilter.languagesFilter}${getFilter.administrativeTypeFilter}${getFilter.specialistTypeFilter}${getFilter.certificationFilter}${getFilter.foundLastName}${getFilter.chosenSpecialities}`)
                    )
                setAllSpecialists(specialistFetch)
                return
            default:
                setActive({specialist: false, officeSpecialist: true})
                const officeSpecialistFetch =
                    await fetchOfficeSpecialists(
                        removeLastAmpersand(`/office_specialists?${getOfficeSpecialistFilter.officeSpecialistTypeFilter}${getOfficeSpecialistFilter.foundFirstName}${getOfficeSpecialistFilter.foundLastName}`)
                    )
                setAllOfficeSpecialists(officeSpecialistFetch)
                return
        }
    }

    return (
        <>
            <Paper
                style={phoneResponsive ? {} : {padding: 15, paddingLeft: 30, marginBottom: 30, marginTop: 0}}
                sx={{
                    marginBottom: 30,
                    padding: '10px 20px',
                    display: 'flex',
                }}
            >
                <div style={{flex: '1 1 100%', marginTop: 10}}>
                    <Button
                        startIcon={<HomeIcon />}
                        onClick={() => handleChangeTab('specialist')}
                        variant={active.specialist ? 'contained' : 'text'}
                        color={darkState ? 'secondary' : 'primary'}
                        style={
                            active.specialist ? {
                                marginRight: phoneResponsive ? 0 : 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                backgroundColor: '#EE8639'
                            } : {
                                marginRight: phoneResponsive ? 0 : 20
                            }
                        }
                    >
                        Spécialistes Pros-Consulte
                    </Button>
                    <Button
                        startIcon={<EmojiTransportationIcon />}
                        onClick={() => handleChangeTab('office_specialists')}
                        variant={active.officeSpecialist ? 'contained' : 'text'}
                        color={darkState ? 'secondary' : 'primary'}
                        style={
                            active.officeSpecialist ? {
                                marginRight: phoneResponsive ? 0 : 20,
                                boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)',
                                backgroundColor: '#27da0d'
                            } : {
                                marginRight: phoneResponsive ? 0 : 20
                            }
                        }
                    >
                        Psychologues en cabinet
                    </Button>
                </div>
            </Paper>
            {active.specialist && allSpecialists ?
                <SpecialistFilters
                    isFromMap={true}
                    newCoordonnate={newCoordonnate}
                    setNewCoordonnate={setNewCoordonnate}
                    activities={activities}
                    setActivities={setActivities}
                    activity={activity}
                    setActivity={setActivity}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    state={state}
                    setState={setState}
                    language={language}
                    setLanguage={setLanguage}
                    languages={languages}
                    setLanguages={setLanguages}
                    speciality={speciality}
                    setSpeciality={setSpeciality}
                    specialities={specialities}
                    setSpecialities={setSpecialities}
                    administrativeType={administrativeType}
                    setAdministrativeType={setAdministrativeType}
                    administrativeTypes={administrativeTypes}
                    setAdministrativeTypes={setAdministrativeTypes}
                    certification={certification}
                    setCertification={setCertification}
                    certifications={certifications}
                    setCertifications={setCertifications}
                    specialistType={specialistType}
                    setSpecialistType={setSpecialistType}
                    specialistTypes={specialistTypes}
                    setSpecialistTypes={setSpecialistTypes}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phoneResponsive={phoneResponsive}
                    setRefresh={setRefresh}
                    setSpecialists={setSpecialists}
                    darkState={darkState}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    zoom={zoom}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                />
            :
                allOfficeSpecialists &&
                <OfficeSpecialistFilters
                    isFromMap={true}
                    newCoordonnate={newCoordonnate}
                    setNewCoordonnate={setNewCoordonnate}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    officeSpecialistType={officeSpecialistType}
                    setOfficeSpecialistType={setOfficeSpecialistType}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phoneResponsive={phoneResponsive}
                    setRefresh={setRefresh}
                    setOfficeSpecialists={setOfficeSpecialists}
                    darkState={darkState}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    zoom={zoom}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                />
            }
            {active.specialist ?
                allSpecialists && specialists && !refresh ? <MapContainer newCoordonnate={newCoordonnate} specialists={specialists} isFromGeneralMap={true} /> :
                <div style={{textAlign: 'center'}}>
                    <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                </div>
            :
                allOfficeSpecialists && officeSpecialists && !refresh ? <MapContainer newCoordonnate={newCoordonnate} officeSpecialists={officeSpecialists} isFromGeneralMap={true} /> :
                <div style={{textAlign: 'center'}}>
                    <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                </div>
            }
        </>
    )
}