import React from 'react'
import { Paper, Zoom, Typography } from '@mui/material';

export default function Specialities({ officeSpecialist = {}, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '300ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Spécialité(s)</span>
                </Typography>
                {officeSpecialist.specialities && officeSpecialist.specialities.length > 0 ? (
                    officeSpecialist.specialities.map((speciality, index) => (
                        <Typography key={index} style={{ marginLeft: 50 }}>
                            • {speciality}
                        </Typography>
                        ))
                    ) : (
                    <Typography style={{marginLeft: 50}}>Pas de spécialitée...</Typography>
                    )}
            </Paper>
        </Zoom>
    )
}