import React from 'react'
import { List, ListItem, ListItemSecondaryAction, ListItemText, IconButton, Grid } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { formatAddress } from '../helpers/utils';
import AutoCompleteAddress from './AutoCompleteAddress';

export default function MultipleAddress({darkState, multipleAddress, setMultipleAddress, disable = false, matches}) {

    const handleFileChanged = (newAddress, newCoordonnate) => {
        setMultipleAddress([...multipleAddress, {address: formatAddress(newAddress, newCoordonnate)}])
    };
    
    const handleDeleteFile = type => _ => {
      const updatedFiles = multipleAddress.filter(f => f.address.latitude !== type)
      setMultipleAddress(updatedFiles)
    };

    return (
        <Grid container spacing={2} style={{marginLeft: '-7px', marginTop: 20, marginBottom: 40}}>
        <span
            style={{
                marginLeft: 10,
                fontSiz: 16,
                colo: darkState ? '#CCC' : '#757575',
                width: '100%',
                marginBottom: 20,
            }}
        >
            Adresse(s) de(s) cabinet(s) : 
        </span>
        <AutoCompleteAddress
            darkState={darkState}
            handleFileChanged={handleFileChanged}
            id="multipleAddress"
            name="multipleAddress"
            label="Adresse du cabinet"
        />
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={3} md={5} style={{marginTop: -20}}>
            <div>
                {multipleAddress.length > 0 && <List>
                    {multipleAddress.map((f,index) => (
                        <ListItem key={index}>
                            <ListItemText
                                style={matches ? {minWidth: 50} : {}}
                                primary={`${f.address.address}`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    disabled={disable}
                                    color='inherit'
                                    edge="end"
                                    aria-label="delete"
                                    onClick={handleDeleteFile(f.address.latitude)}
                                    style={matches ? {marginRight: '-48px', marginTop: 25} : {}}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>}
            </div>
        </Grid>
      </Grid>
    )
}